import React, {createElement , useEffect, useState} from "react";
import bottonIcon from "assets/image/botton_menu.png";
import LinkTo from "components/LinkTo";
import AppIcons from "components/AppIcons/index";
import MenuLabel from './MenuLabel'
import NotificationAlert from '../BottomMenu/NotificationAlert/index';
import ProfileService from "services/profile/ProfileService";
import eventBus from "commons/utils/EventBus";
import { useHistory } from "react-router-dom";
import { TeamService } from "services";
import { useSelector } from "react-redux";

import {
  Container,
  IconContainer,
  ShadowContainer,
  IconWrapper,
  NotificationIconStyled
} from "./BottomMenu_style.js";
import './BottomMenu.scss'
import { changeS3UrlToCdn } from "hooks/useCdn";

const BottomMenu = ({
  color1,
  color2,
  hasBeebot,
  selected,
  teamVisualIdentity: {
    bot_image: botImage,
    custom_modules: customModules,
    white_label: whiteLabel,
  },
}) => {
  const [customNameModules, setCustomNameModules] = useState([]);
  const [courses, setCourses] = useState(0);
  const [lightningTest, setLightningTest] = useState(0);
  const [show, setShow] = useState(true);
  const [doubleClickBlock, setDoubleClickBlock] = useState(true);
  const [timeoutId, setTimeoutId] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  const [useBeebotNavigatorFront, setUseBeebotNavigatorFront] = useState(true);
  const history = useHistory();
  const custom = useSelector(state => state.teamState.config)
  useEffect(() => {
    setCourseName()
    getNotificationData()
    setShowFooter()
    setDoubleClickBlock(true)
    eventBus.on("quizpopanswered", () => {
      setLightningTest(lightningTest - 1);
    });
    const getProfileInfo = async () => {
      const profileService = new ProfileService(history);
      const [
        profile,
        poweraApps,
      ] = await Promise.all([
        profileService.getProfile(),
        TeamService.getPowerApps(),
      ])
      const {user} = profile
      const powerappsList = {data: poweraApps}
      setUserProfile({...user, powerappsList, custom})
    }
    getProfileInfo()
  }, []);
  const refreshPage = (page) => {
    clearTimeout(timeoutId);
    const idTimeout = setTimeout(() => setDoubleClickBlock(false), 5000);
    setTimeoutId(idTimeout);
    if (doubleClickBlock) return;
    
    const userSelectedSameIcon = page === selected;

    setDoubleClickBlock(true);
    if (userSelectedSameIcon && !doubleClickBlock) return window.location.reload(false);
  }
  const setShowFooter = () => {

    eventBus.on("quizpopanswered", () => {
      setLightningTest(lightningTest - 1);
    });
  
  }
  const setCourseName = () => {
    if(customModules && customModules.length > 0) {
      const moduleNames = customModules.reduce((acc, module) => {
        return { ...acc, [module.module_id]: module.module_name };
      }, {});
      setCustomNameModules(moduleNames);
    }
  }
  const getNotificationData = async () => {
    const profile = new ProfileService(true)
    const dataNotifications = await profile.getNotifications()
    setCourses(dataNotifications.courses[0].value);
    setLightningTest(dataNotifications.lightningTest[0].value);
  }
  const icon = ({
    click,
    icon,
    label,
    notificationAlert,
    pageName,
    to,
  }) => {
    const pageSelected = selected.split('/')[1]
    const isSelected = pageName.some(page => page === pageSelected)
    const color = isSelected ? color1 : color2
    const fontWeight = isSelected ? '500' : 'normal'
    return (
      <LinkTo onClick={click} to={to} className="menu-item">
        <IconContainer>
          <div className="menu-icon-container">
            <NotificationIconStyled>
              <NotificationAlert
                notification={notificationAlert}
              />
              <AppIcons icon={icon} color={color} size={28} />
            </NotificationIconStyled>
            <MenuLabel label={label} color={color} fontWeight={fontWeight}/>
          </div>
        </IconContainer>
      </LinkTo>
    );
  }
  const beebotIcon = () => {
    if (!hasBeebot) return false;
    const isWhiteLabel = parseInt(whiteLabel) === 1;
    const isDefaultImageBot = botImage.indexOf("BOT.png") !== -1;
    const beetBotClassName = isDefaultImageBot ? "beebotMenuWhiteLabelDefault" : 'beebotMenuWhiteLabel'
    return (
      <LinkTo onClick={() => refreshPage("/chat")} to={"/chat"} className="menu-item">
        <div className="menu-icon-container">
          <img
            src={changeS3UrlToCdn(isWhiteLabel ? botImage : bottonIcon)}
            alt="Logo" 
            className={isWhiteLabel ? beetBotClassName : "beebotMenuNoWhiteLabel"}
            style={{filter: ""}}
          />
        </div>
      </LinkTo>
    );
  }
  const getLabel = (maxLenghtLabel, nameCourse) => {
    return ((nameCourse).length > maxLenghtLabel) ? (((nameCourse).substring(0, maxLenghtLabel - 3)) + '...') : nameCourse
  }
  const feedIconParams = {
    click: () => refreshPage('/home'),
    icon: 'home',
    label: 'Feed',
    pageName: ['home', 'feed'],
    to: '/home',
  }
  const wikiIconParams = {
    click: () => refreshPage('/wiki'),
    icon: 'lightning',
    label: getLabel(11, customNameModules.wiki || 'Wiki'),
    pageName: ['wiki', 'postOpen'],
    to: '/wiki',
  }
  const lightningTestIconParams = {
    click: () => refreshPage("/quizpop"),
    icon: 'quizpop',
    label: "Teste Rel...",
    notificationAlert: lightningTest,
    pageName: ['quizpop'],
    to: "/quizpop",
  }
  const quizIconParams = {
    click: () => refreshPage("/quiz"),
    icon: 'quiz',
    label: getLabel(11, customNameModules.quiz || 'Quiz'),
    notificationAlert: courses,
    pageName: ['quiz'],
    to: "/quiz",
  }

  return (
    <Container show={show}>
      <ShadowContainer className="shadow2">
        <IconWrapper className="maxWidth">
          {icon(feedIconParams)}
          {icon(wikiIconParams)}
          {useBeebotNavigatorFront
            ? userProfile && <BeebotScript
              src={process.env.REACT_APP_BEEBOT_NAVIGATOR_FRONT_SRC}
              profile={userProfile}
              apiNode={process.env.REACT_APP_API_URL}
            />
            : beebotIcon()
          }
          {icon(lightningTestIconParams)}
          {icon(quizIconParams)}
        </IconWrapper>
      </ShadowContainer>
      
    </Container>
  );
}

const BeebotScript = ({src, profile, apiNode}) => {
  useEffect(() => {
    const apiToken = localStorage.getItem('@beedookey');
    const script = document.createElement('script');
    script.src = src
    script.async = true
    script.onload = () => {
      window.init({
        apiNode,
        device: 'mobile',
        navigator: src.replace('/init.js', ''),
        parentSelector: '#beebot-container',
        profile: {...profile, api_token: apiToken},
        wiki: {
          name: 'Wiki',
          article: {
            pathname: window.location.pathname,
          },
        }
      });
    }
    document.body.appendChild(script);
  }, []);
  return (<div id="beebot-container" />);
};

export default BottomMenu;
